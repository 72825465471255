import heroShot from './../../assets/videos/hero-shot.mp4';
import heroShotPoster from './../../assets/images/hero-shot-poster.jpg';
import './../../assets/sass/hero.scss';
import Logo from "../components/Logo";


export default function HeroSection() {
    return (
        <section className="hero-section">
            <div className="hero-shot-wrapper position-relative overflow-hidden p-0 bg-black lh-0">
                <video
                    className=""
                    src={heroShot}
                    poster={heroShotPoster}
                    autoPlay
                    muted
                    loop
                    playsInline
                    controlsList="nofullscreen"
                ></video>
                <div className="video-overlay position-absolute bottom-0 start-0 end-0"></div>
            </div>

            <div className="bg-accent-black">
                <div className="p-2 p-lg-5">
                    <div className="logo-sm py-3 text-center">
                        <Logo/>
                    </div>

                    <div className="">
                        <p className="m-0 py-5 fw-normal text-center m-auto text-uppercase text-white hero-text">
                            we enable and empower people to
                            lead a better life through our
                            reimagined destinations.
                        </p>
                    </div>

                    <div className="people-places py-4 text-white text-uppercase text-center">
                        <span className="me-2">People</span>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="15.591"
                             height="13.572"
                             viewBox="0 0 15.591 13.572"
                        >
                            <path
                                d="M8.291,0,7.7.591l5.172,5.591H0V7.508H12.768L7.7,12.981l.591.591,6.139-5.707h0l1.16-1.08L14.433,5.71Z"
                                fill="#f4f4f4"
                            />
                        </svg>
                        <span className="ms-2">places</span>
                    </div>
                </div>
            </div>

        </section>
    )
}