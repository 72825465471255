import {Swiper, SwiperSlide} from "swiper/react";
import {EffectFade,Autoplay,  Pagination} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import '../../assets/sass/what-we-do.scss';


export default function WhatWeDoSection() {
    return (
        <section className='what-we-do-section'>
            <span className="overlay"></span>
            <div className="slider-wrapper">
                <div className="title">
                    What we do
                </div>
                <div className="container">
                    <Swiper
                        spaceBetween={30}
                        effect={'fade'}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[EffectFade, Pagination, Autoplay]}
                        slidesPerView={1}
                        autoplay={{
                            delay: 1500,
                        }}
                        speed={1000}
                        loop={true}
                        className="what-we-do-slider"
                    >
                        <SwiperSlide>
                            <div
                                className="slider-item__inner"
                                role="button"
                            >
                                Commercial <br/>Developments
                            </div>
                        </SwiperSlide>

                        {/*<SwiperSlide>
                            <div
                                className="slider-item__inner"
                                role="button"
                            >
                                Development <br/> Strategy
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div
                                className="slider-item__inner"
                                role="button"
                            >
                                Design &amp; Build
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div
                                className="slider-item__inner"
                                role="button"
                            >
                                Property <br/>Management
                            </div>
                    </SwiperSlide>*/}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}